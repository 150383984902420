/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Modal, Box, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionUpdateMealInput,
  actionUpdateMeal,
  actionResetMeal,
} from '../../redux/actions';
import Button from '../Buttons/Button/Button';

import './modal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'rgba(94, 94, 94, 0.865)',
  color: '#fff',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

function CreatMealModal({
  openModal,
  closeModal,
  modalTitle,
  lunch,
  drink,
}) {
  const dispatch = useDispatch();

  const {
    title, quarterprice, halfprice, literprice, price, description,
  } = useSelector((state) => state.meal);

  const handleChange = (key, value) => {
    dispatch(actionUpdateMealInput(key, value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionUpdateMeal());
    closeModal();
  };

  const handleClose = () => {
    dispatch(actionResetMeal());
    closeModal();
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal__title" variant="h4" sx={{ textAlign: 'center' }}>
          {modalTitle}
        </Typography>
        <form className="modal__form" onSubmit={handleSubmit}>
          <div className="modal__form-info">
            <label className="modal__form-info-name">
              Nom
              <input
                className="modal__form-info-input"
                required
                type="text"
                value={title}
                id="name_input"
                name="title"
                placeholder="Nom"
                onChange={(event) => handleChange('title', event.target.value)}
              />
            </label>
            {!lunch && (
            <label className="modal__form-info-price">
              Prix
              <div className="modal__form-info-price-content">
                <input
                  className="modal__form-input-price"
                  type="text"
                  value={price}
                  id="name_input"
                  name="title"
                  placeholder="Prix"
                  onChange={(event) => handleChange('price', event.target.value)}
                />
                <p className="modal__form-input-price-content">€</p>
              </div>
            </label>
            )}
          </div>
          {drink && (
            <div className="modal__form-info">
              <label className="modal__form-info-price">
                Prix 25cl
                <div className="modal__form-info-price-content">
                  <input
                    className="modal__form-input-price"
                    type="text"
                    value={quarterprice}
                    id="name_input"
                    name="title"
                    placeholder="Prix"
                    onChange={(event) => handleChange('quarterprice', event.target.value)}
                  />
                  <p className="modal__form-input-price-content">€</p>
                </div>
              </label>
              <label className="modal__form-info-price">
                Prix 50cl
                <div className="modal__form-info-price-content">
                  <input
                    className="modal__form-input-price"
                    type="text"
                    value={halfprice}
                    id="name_input"
                    name="title"
                    placeholder="Prix"
                    onChange={(event) => handleChange('halfprice', event.target.value)}
                  />
                  <p className="modal__form-input-price-content">€</p>
                </div>
              </label>
              <label className="modal__form-info-price">
                Prix 1L
                <div className="modal__form-info-price-content">
                  <input
                    className="modal__form-input-price"
                    type="text"
                    value={literprice}
                    id="name_input"
                    name="title"
                    placeholder="Prix"
                    onChange={(event) => handleChange('literprice', event.target.value)}
                  />
                  <p className="modal__form-input-price-content">€</p>
                </div>
              </label>
            </div>
          )}
          <label className="modal__form-label">
            Description
            <textarea
              className="modal__form-textarea"
              name="description"
              placeholder="Description"
              onChange={(event) => handleChange('description', event.target.value)}
              value={description}
            />
          </label>
          <div className="modal__form-button">
            <Button classname="normal-button cancel modal__form-button-button" type="button" name="Annuler" onClick={handleClose} />
            <Button classname="normal-button validate modal__form-button-button" type="submit" name="Modifier" />
          </div>
        </form>
      </Box>
    </Modal>
  );
}

CreatMealModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  lunch: PropTypes.bool,
  drink: PropTypes.bool,
};
CreatMealModal.defaultProps = {
  lunch: false,
  drink: false,
};
export default React.memo(CreatMealModal);
