import apiAxios from './index';

/**
 * function used to make a http request to get all meals
 * @returns response with meals in an array of object
 */
export async function getAllMeals() {
  try {
    const response = await apiAxios.get('/meals');
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to get one meal
 * @param {string} id id of the meal you want to get
 * @returns response with meal in an object
 */
export async function getOneMeal(id) {
  try {
    const response = await apiAxios.get(`/meals/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to create a meal
 * @param {object} mealDatas meal informations (title, price, description, menupart_id)
 * @returns response with new meal in an object
 */
export async function createMeal(mealsDatas) {
  try {
    const response = await apiAxios.post('/meals', mealsDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to update a meal
 * @param {string} id id of the meal you want to update
 * @param {object} mealsDatas meal informations (title, price, description, menupart_id)
 * @returns response with updated meal in an object
 */
export async function updateMeal(id, mealsDatas) {
  try {
    const response = await apiAxios.patch(`/meals/${id}`, mealsDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to delete a meal
 * @param {string} id id of the meal you want to delete
 * @returns response with an empty object
 */
export async function deleteMeal(id) {
  try {
    const response = await apiAxios.delete(`/meals/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}
