import apiAxios from './index';

/**
 * function used to make a http request to get all cards
 * @returns response with cards in an array of object
 */
export async function getAllCards() {
  try {
    const response = await apiAxios.get('/cards');
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to get one card
 * @param {string} id id of the card you want to get
 * @returns response with card in an object
 */
export async function getOneCard(id) {
  try {
    const response = await apiAxios.get(`/cards/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to create a card
 * @param {object} cardDatas card informations (name)
 * @returns response with new card in an object
 */
export async function createCard(cardDatas) {
  try {
    const response = await apiAxios.post('/cards', cardDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to update a card
 * @param {string} id id of the card you want to update
 * @param {object} cardDatas card informations (name)
 * @returns response with updated card in an object
 */
export async function updateCard(id, cardDatas) {
  try {
    const response = await apiAxios.patch(`/cards/${id}`, cardDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to delete a card
 * @param {string} id id of the card you want to delete
 * @returns response with an empty object
 */
export async function deleteCard(id) {
  try {
    const response = await apiAxios.delete(`/cards/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}
