import React from 'react';
// import PropTypes from 'prop-types';
import './notFoundPage.scss';

function NotFoundPage() {
  return (
    <div className="notFoundPage">
      <div className="notFoundPage__container">
        <h1 className="notFoundPage__title">
          Il n&apos;y a rien à boire et à manger ici ...
        </h1>
        <a href="/" className="notFoundPage__link">Retourne au comptoir</a>
      </div>
    </div>
  );
}

NotFoundPage.propTypes = {
};
NotFoundPage.defaultProps = {
};
export default React.memo(NotFoundPage);
