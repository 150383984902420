export const SET_VIDEO_INFOS = 'SET_VIDEO_INFOS';
export const SET_VIDEO_OWNER_ID = 'SET_VIDEO_OWNER_ID';
export const CREATE_VIDEO = 'CREATE_VIDEO';
export const CREATE_VIDEO_EVENT = 'CREATE_VIDEO_EVENT';
export const REMOVE_VIDEO_FROM_FIREBASE = 'REMOVE_VIDEO_FROM_FIREBASE';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const RESET_VIDEO = 'RESET_VIDEO';

export function actionSetVideoInfos(data) {
  return { type: SET_VIDEO_INFOS, payload: data };
}

export function actionSetVideoOwnerId(key, value) {
  return { type: SET_VIDEO_OWNER_ID, payload: { key, value } };
}

export function actionCreateVideo() {
  return { type: CREATE_VIDEO };
}

export function actionCreateEventVideo() {
  return { type: CREATE_VIDEO_EVENT };
}

export function actionRemoveVideoFromFirebase() {
  return { type: REMOVE_VIDEO_FROM_FIREBASE };
}

export function actionDeleteVideo() {
  return { type: DELETE_VIDEO };
}

export function actionResetVideo() {
  return { type: RESET_VIDEO };
}
