export const UPDATE_CARD_INPUT = 'UPDATE_CARD_INPUT';
export const GET_ALL_CARDS = 'GET_ALL_CARDS';
export const SET_ALL_CARDS = 'SET_ALL_CARDS';
export const GET_ONE_CARD = 'GET_ONE_CARD';
export const SET_ONE_CARD = 'SET_ONE_CARD';
export const SET_CARD_INFOS = 'SET_CARD_INFOS';
export const CREATE_CARD = 'CREATE_CARD';
export const UPDATE_CARD = 'UPDATE_CARD';
export const DELETE_CARD = 'DELETE_CARD';
export const RESET_CARD_INPUT = 'RESET_CARD_INPUT';
export const RESET_CARD = 'RESET_CARD';

export function actionUpdateCardInput(name, value) {
  return {
    type: UPDATE_CARD_INPUT, payload: { name, value },
  };
}

export function actionGetAllCards() {
  return { type: GET_ALL_CARDS };
}

export function actionSetAllCards(datas) {
  return { type: SET_ALL_CARDS, payload: datas };
}

export function actionGetOneCard() {
  return { type: GET_ONE_CARD };
}

export function actionSetOneCard(data) {
  return {
    type: SET_ONE_CARD, payload: data,
  };
}

export function actionSetCardInfos(data) {
  return {
    type: SET_CARD_INFOS, payload: data,
  };
}

export function actionCreateCard() {
  return { type: CREATE_CARD };
}

export function actionUpdateCard() {
  return { type: UPDATE_CARD };
}

export function actionDeleteCard() {
  return { type: DELETE_CARD };
}

export function actionResetCardInput() {
  return { type: RESET_CARD_INPUT };
}

export function actionResetCard() {
  return { type: RESET_CARD };
}
