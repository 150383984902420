/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  Modal, Box, Typography, Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  actionUpdateMenupartInput,
  actionResetMenupartInput,
  actionUpdateMenupart,
  actionSetPhotoInfos,
  actionRemovePhotoFromFirebase,
  actionDeleteMenupart,
  actionResetPhoto,
} from '../../redux/actions';
import Button from '../Buttons/Button/Button';
import FileInput from '../FileInput/FileInput';

import './modal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'rgba(94, 94, 94, 0.865)',
  color: '#fff',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

function EditMenupartModal({
  openModal,
  closeModal,
  modalTitle,
  lunch,
}) {
  const dispatch = useDispatch();
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    id, name, ordinal, description,
  } = useSelector((state) => state.menupart);

  const photo = useSelector((state) => state.photo);

  useEffect(() => {
    if (photo.title) {
      setIsInputDisabled(true);
    } else {
      setIsInputDisabled(false);
    }
  }, [photo]);

  const handleImageState = (fileName, fileUrl) => {
    const data = {
      id: 0,
      title: fileName,
      url: fileUrl,
      menupart_id: id,
    };
    dispatch(actionSetPhotoInfos(data));
  };

  const handleDeletePhoto = () => {
    dispatch(actionRemovePhotoFromFirebase());
  };

  const handleChange = (key, value) => {
    dispatch(actionUpdateMenupartInput(key, value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionUpdateMenupart());
    dispatch(actionResetMenupartInput());
    closeModal();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(actionDeleteMenupart());
    closeModal();
  };

  const handleClose = () => {
    dispatch(actionResetMenupartInput());
    dispatch(actionResetPhoto());
    closeModal();
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal__title" variant="h4" sx={{ textAlign: 'center' }}>
          {modalTitle}
        </Typography>
        <form className="modal__form" onSubmit={handleSubmit}>
          <label className="modal__form-label">
            Nom de la catégorie
            <input
              className="modal__form-input"
              required
              type="text"
              value={name}
              id="name_input"
              name="title"
              placeholder="Nom de la catégorie"
              onChange={(event) => handleChange('name', event.target.value)}
            />
          </label>
          <label className="modal__form-label">
            Ordre dans le menu
            <input
              className="modal__form-input"
              required
              type="number"
              value={ordinal}
              id="name_input"
              name="title"
              min="1"
              onChange={(event) => handleChange('ordinal', event.target.value)}
              style={{ width: '10%', margin: '1rem auto 0', textAlign: 'center' }}
            />
          </label>
          {!lunch && (
            <>
              <label className="modal__form-label">
                Description
                <textarea
                  className="modal__form-textarea"
                  name="description"
                  placeholder="Description de la catégorie"
                  onChange={(event) => handleChange('description', event.target.value)}
                  value={description}
                />
              </label>
              <div className="modal__form-file-container-content">
                {isInputDisabled ? (
                  <Tooltip title="Vous devez d'abord supprimer l'ancienne image" placement="bottom">
                    <span>
                      <FileInput
                        name="image"
                        label="Ajouter une image"
                        handleInputState={handleImageState}
                        setEventIsDisabled={setIsDisabled}
                        accept="image/*,.pdf"
                        isInputDisabled={isInputDisabled}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <FileInput
                    name="image"
                    label="Ajouter une image"
                    handleInputState={handleImageState}
                    setEventIsDisabled={setIsDisabled}
                    accept="image/*,.pdf"
                    isInputDisabled={isInputDisabled}
                  />
                )}
                {photo.title && (
                <div className="modal__form-file-container-content-img">
                  <img src={photo.url} alt={photo.title} className="modal__form-file-container-content-img-preview" />
                  <p>
                    {photo.title.split('.')[0]}
                  </p>
                  <button type="button" className="delete" onClick={handleDeletePhoto}>
                    <FontAwesomeIcon icon={faTrash} size="2x" />
                  </button>
                </div>
                )}
              </div>
            </>
          )}
          <div className="modal__form-button">
            <Button classname="normal-button cancel modal__form-button-button" type="button" name="Supprimer" onClick={handleDelete} />
            <Button classname="normal-button cancel modal__form-button-button" type="button" name="Annuler" onClick={handleClose} />
            <Button classname="normal-button validate modal__form-button-button" type="submit" name="Modifier" disabled={isDisabled} />
          </div>
        </form>
      </Box>
    </Modal>
  );
}

EditMenupartModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  lunch: PropTypes.bool,
};
EditMenupartModal.defaultProps = {
  lunch: false,
};
export default React.memo(EditMenupartModal);
