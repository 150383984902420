/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, Outlet } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AdminMain from '../AdminMain/AdminMain';
import './menuPage.scss';

function MenuPage() {
  return (
    <>
      <Helmet>
        <title>Admin - Menus</title>
        <meta
          name="robots"
          content="noindex"
        />
        <link rel="canonical" href="/admin/menus" />
      </Helmet>
      <AdminMain>
        <div className="menupage">
          <h2 className="menupage__title">Gestion des menus et des plats</h2>
          <div className="menupage__content">
            <nav className="menupage__content-nav">
              <Tooltip title="Modifier le plat du jour">
                <span>
                  <NavLink
                    to="lunch"
                    className={({ isActive }) => (isActive ? 'menupage__content-nav-link active' : 'menupage__content-nav-link')}
                  >
                    Gestion du plat du jour
                  </NavLink>
                </span>
              </Tooltip>
              <Tooltip title="Ajouter, Modifier, Supprimer les plats de la carte">
                <span>
                  <NavLink
                    to="card"
                    className={({ isActive }) => (isActive ? 'menupage__content-nav-link active' : 'menupage__content-nav-link')}
                  >
                    Gestion de la carte des plats
                  </NavLink>
                </span>
              </Tooltip>
              <Tooltip title="Ajouter, Modifier, Supprimer les boissons de la carte">
                <span>
                  <NavLink
                    to="drink"
                    className={({ isActive }) => (isActive ? 'menupage__content-nav-link active' : 'menupage__content-nav-link')}
                  >
                    Gestion de la carte des boissons
                  </NavLink>
                </span>
              </Tooltip>
              {/* <Tooltip title="Ajouter, Modifier, Supprimer un menu">
              <button disabled type="button" className={({ isActive }) => (isActive ? 'menupage__content-nav-link active' : 'menupage__content-nav-link')}>
                Gestion des menus
              </button>
            </Tooltip> */}
            </nav>
          </div>
          <Outlet />
        </div>
      </AdminMain>
    </>
  );
}

MenuPage.propTypes = {
};
MenuPage.defaultProps = {
};
export default React.memo(MenuPage);
