/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

function Button({
  title,
  type,
  classname,
  name,
  onClick,
  disabled,
}) {
  return (
    <button className={disabled ? 'disabled' : `${classname}`} onClick={onClick} type={type} title={title} disabled={disabled}>
      {name}
    </button>
  );
}

Button.propTypes = {
  title: PropTypes.string,
  classname: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
Button.defaultProps = {
  title: '',
  onClick: null,
  disabled: false,
};
export default React.memo(Button);
