/* eslint-disable camelcase */
import {
  getAllCards, getOneCard, createCard, updateCard, deleteCard,
} from '../../utils/requests/cardRequest';
import * as actions from '../actions';

const cardMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_CARDS: {
      const response = await getAllCards();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllCards(response.data));
      }
      return;
    }

    case actions.GET_ONE_CARD: {
      const { card } = store.getState();
      const response = await getOneCard(card.id);
      if (response.status === 200) {
        store.dispatch(actions.actionSetCardInfos(response.data));
        store.dispatch(actions.actionSetOneCard(response.data));
        if (response.data.menuparts) {
          store.dispatch(actions.actionSetAllMenuparts(response.data.menuparts));
        }
      }
      return;
    }

    case actions.CREATE_CARD: {
      const { card } = store.getState();
      const {
        name,
        price,
        event_id,
      } = card;
      const cardDatas = {
        name,
        price,
        event_id,
      };
      const response = await createCard(cardDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetCardInput());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    case actions.UPDATE_CARD: {
      const { card } = store.getState();
      const {
        name,
        price,
        event_id,
      } = card;
      const cardDatas = {
        name,
        price,
        event_id,
      };
      const response = await updateCard(card.id, cardDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetCardInput());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    case actions.DELETE_CARD: {
      const { card } = store.getState();
      const response = await deleteCard(card.id);
      if (response.status === 204) {
        store.dispatch(actions.actionResetCardInput());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    default:
      next(action);
  }
};

export default cardMiddleware;
