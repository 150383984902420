/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Modal, Box, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionUpdateCardInput,
  actionUpdateCard,
  actionResetMenupartInput,
  actionResetPhoto,
} from '../../redux/actions';
import Button from '../Buttons/Button/Button';

import './modal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'rgba(94, 94, 94, 0.865)',
  color: '#fff',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

function EditCardModal({
  openModal,
  closeModal,
  modalTitle,
  setIsCardChange,
  isCardChange,
  lunch,
}) {
  const dispatch = useDispatch();

  const {
    name, price,
  } = useSelector((state) => state.card);

  const handleChange = (key, value) => {
    dispatch(actionUpdateCardInput(key, value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionUpdateCard());
    setIsCardChange(!isCardChange);
    closeModal();
  };

  const handleClose = () => {
    dispatch(actionResetMenupartInput());
    dispatch(actionResetPhoto());
    closeModal();
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal__title" variant="h4" sx={{ textAlign: 'center' }}>
          {modalTitle}
        </Typography>
        <form className="modal__form" onSubmit={handleSubmit}>
          {lunch ? (
            <label className="modal__form-info-price">
              Prix
              <div className="modal__form-info-price-contentMenu">
                <input
                  className="modal__form-input-price"
                  type="text"
                  value={price}
                  id="name_input"
                  name="title"
                  placeholder="Prix"
                  onChange={(event) => handleChange('price', event.target.value)}
                />
                <p className="modal__form-input-price-content">€</p>
              </div>
            </label>
          ) : (
            <div className="modal__form-info">
              <label className="modal__form-info-name">
                Nom
                <input
                  className="modal__form-info-input"
                  required
                  type="text"
                  value={name}
                  id="name_input"
                  name="title"
                  placeholder="Nom"
                  onChange={(event) => handleChange('name', event.target.value)}
                />
              </label>
              <label className="modal__form-info-price">
                Prix
                <div className="modal__form-info-price-content">
                  <input
                    className="modal__form-input-price"
                    required
                    type="text"
                    value={price}
                    id="name_input"
                    name="title"
                    placeholder="Prix"
                    onChange={(event) => handleChange('price', event.target.value)}
                  />
                  <p className="modal__form-input-price-content">€</p>
                </div>
              </label>
            </div>
          )}
          <div className="modal__form-button">
            <Button classname="normal-button cancel modal__form-button-button" type="button" name="Annuler" onClick={handleClose} />
            <Button classname="normal-button validate modal__form-button-button" type="submit" name="Modifier" />
          </div>
        </form>
      </Box>
    </Modal>
  );
}

EditCardModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  setIsCardChange: PropTypes.func,
  isCardChange: PropTypes.bool,
  lunch: PropTypes.bool,
};
EditCardModal.defaultProps = {
  setIsCardChange: null,
  isCardChange: false,
  lunch: false,
};
export default React.memo(EditCardModal);
