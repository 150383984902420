import React from 'react';
// import PropTypes from 'prop-types';
import './maintenancePage.scss';

function MaintenancePage() {
  return (
    <div className="maintenancePage">
      <div className="maintenancePage__container">
        <h1 className="maintenancePage__title">
          Le site est actuellement en maintenance...
        </h1>
      </div>
    </div>
  );
}

MaintenancePage.propTypes = {
};
MaintenancePage.defaultProps = {
};
export default React.memo(MaintenancePage);
