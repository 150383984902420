import React from 'react';
import { Navigate } from 'react-router-dom';

function HomeContainer() {
  return <Navigate to="/home" replace />;
}

HomeContainer.propTypes = {
};
HomeContainer.defaultProps = {
};
export default React.memo(HomeContainer);
