/* eslint-disable quotes */
import React from "react";
import { Route, Routes } from "react-router-dom";
// import HomePage from "../HomePage/HomePage";
// import CartePage from "../CartePage/CartePage";
// import EventConcertPage from "../EventConcertPage/EventConcertPage";
// import DrinkPage from "../DrinkPage/DrinkPage";
// import ContactPage from "../ContactPage/ContactPage";
import ConfPage from "../ConfPol/ConfPol";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import HomeContainer from "../../container/HomeContainer";
import RequireAuth from "../RequireAuth/RequireAuth";
import LoginPage from "../LoginPage/LoginPage";
import AdminHomePage from "../AdminHomePage/AdminHomePage";
import EventPage from "../EventPage/EventPage";
import MenuPage from "../MenuPage/MenuPage";
import CardContainer from "../../container/CardContainer";
import UpdateEvent from "../EventPage/UpdateEvent/UpdateEvent";
import AddEvent from "../EventPage/AddEvent/AddEvent";
import DeleteEvent from "../EventPage/DeleteEvent/DeleteEvent";
import MaintenancePage from "../MaintenancePage/MaintenancePage";
// import Menu from '../MenuPage/Menu/Menu';
// import CreateMenu from '../MenuPage/Menu/CreateMenu/CreateMenu';
// import UpdateMenu from '../MenuPage/Menu/UpdateMenu/UpdateMenu';
// import DeleteMenu from '../MenuPage/Menu/DeleteMenu/DeleteMenu';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeContainer />} />
      <Route path="/home" element={<MaintenancePage />} />
      <Route path="/card" element={<MaintenancePage />} />
      <Route path="/drinks" element={<MaintenancePage />} />
      <Route path="/events" element={<MaintenancePage />} />
      <Route path="/contact" element={<MaintenancePage />} />
      <Route path="/politiques-confidentialite" element={<ConfPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin" element={<RequireAuth />}>
        <Route path="home" element={<AdminHomePage />} />
        <Route path="event" element={<EventPage />}>
          <Route path="add" element={<AddEvent />} />
          <Route path="update" element={<UpdateEvent />} />
          <Route path="delete" element={<DeleteEvent />} />
        </Route>
        <Route path="menus" element={<MenuPage />}>
          <Route path="card" element={<CardContainer />} />
          <Route path="lunch" element={<CardContainer />} />
          <Route path="drink" element={<CardContainer />} />
          {/* <Route path="menu" element={<Menu />}>
            <Route path="create" element={<CreateMenu />} />
            <Route path="update" element={<UpdateMenu />} />
            <Route path="delete" element={<DeleteMenu />} />
          </Route> */}
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default React.memo(App);
