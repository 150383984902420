/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import {
  actionUpdateEventInput,
  actionCreateEvent,
  actionResetEvent,
  actionSetPhotoInfos,
  actionSetVideoInfos,
  actionResetPhoto,
  actionResetVideo,
  actionRemoveVideoFromFirebase,
  actionRemovePhotoFromFirebase,
} from '../../../redux/actions';
import Button from '../../Buttons/Button/Button';
import FileInput from '../../FileInput/FileInput';
import './addEvent.scss';

function AddEvent() {
  const dispatch = useDispatch();
  const {
    title, date, group, description,
  } = useSelector((state) => state.event);
  const photo = useSelector((state) => state.photo);
  const video = useSelector((state) => state.video);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInputPhotoDisabled, setIsInputPhotoDisabled] = useState(false);
  const [isInputVideoDisabled, setIsInputVideoDisabled] = useState(false);

  const dateInFormat = moment(date).format('YYYY-MM-DD');

  const handleChange = (key, value) => {
    dispatch(actionUpdateEventInput(key, value));
  };

  const handleImageState = (fileName, fileUrl) => {
    const data = {
      id: 0,
      title: fileName,
      url: fileUrl,
    };
    dispatch(actionSetPhotoInfos(data));
  };

  const handleVideoState = (fileName, fileUrl) => {
    const data = {
      id: 0,
      title: fileName,
      url: fileUrl,
    };
    dispatch(actionSetVideoInfos(data));
  };

  const handleDeleteVideo = () => {
    dispatch(actionRemoveVideoFromFirebase());
  };

  const handleDeletePhoto = () => {
    dispatch(actionRemovePhotoFromFirebase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(actionCreateEvent());
  };

  useEffect(() => {
    dispatch(actionResetEvent());
    dispatch(actionResetPhoto());
    dispatch(actionResetVideo());
  }, []);

  useEffect(() => {
    if (photo.title && video.title) {
      setIsInputPhotoDisabled(true);
      setIsInputVideoDisabled(true);
      return;
    }
    if (photo.title || video.title) {
      if (photo.title) {
        setIsInputPhotoDisabled(true);
        setIsInputVideoDisabled(false);
      }
      if (video.title) {
        setIsInputPhotoDisabled(false);
        setIsInputVideoDisabled(true);
      }
      return;
    }
    setIsInputPhotoDisabled(false);
    setIsInputVideoDisabled(false);
  }, [photo, video]);

  return (
    <>
      <Helmet>
        <title>Admin - Event</title>
        <meta
          name="robots"
          content="noindex"
        />
        <link rel="canonical" href="/admin/event/add" />
      </Helmet>
      <div className="addEvent-page">
        <h3 className="addEvent-page__title">Créer un évènement</h3>
        <form className="addEvent-page__form" onSubmit={handleSubmit}>
          <div className="addEvent-page__form-container">
            <label className="addEvent-page__form-label">
              Nom de l&apos;évènement
              <input
                className="addEvent-page__form-input"
                required
                type="text"
                value={title}
                id="name_input"
                name="title"
                placeholder="Nom de l'évènement"
                onChange={(event) => handleChange('title', event.target.value)}
              />
            </label>
            <label className="addEvent-page__form-label">
              Date de l&apos;évènement
              <input
                required
                className="addEvent-page__form-input"
                type="date"
                value={dateInFormat}
                min={moment().format('YYYY-MM-DD')}
                id="name_input"
                name="date"
                onChange={(event) => handleChange('date', event.target.value)}
              />
            </label>
            <label className="addEvent-page__form-label">
              Nom du groupe
              <input
                className="addEvent-page__form-input"
                type="text"
                value={group}
                id="name_input"
                name="group"
                placeholder="Nom du groupe"
                onChange={(event) => handleChange('group', event.target.value)}
              />
            </label>
          </div>
          <div className="addEvent-page__form-container">
            <label className="addEvent-page__form-label-textarea">
              Description de l&apos;évènement
              <textarea
                className="addEvent-page__form-textarea"
                name="description"
                rows="8"
                cols="100"
                placeholder="Description de l'évènement"
                onChange={(event) => handleChange('description', event.target.value)}
                value={description}
              />
            </label>
          </div>
          <div className="addEvent-page__form-file-container">
            <div className="addEvent-page__form-file-container-content">
              {isInputPhotoDisabled ? (
                <Tooltip title="Vous devez d'abord supprimer l'ancienne image" placement="bottom">
                  <span>
                    <FileInput
                      name="image"
                      label="Ajouter une image"
                      handleInputState={handleImageState}
                      setEventIsDisabled={setIsDisabled}
                      accept="image/*,.pdf"
                      isInputDisabled={isInputPhotoDisabled}
                    />
                  </span>
                </Tooltip>
              ) : (
                <FileInput
                  name="image"
                  label="Ajouter une image"
                  handleInputState={handleImageState}
                  setEventIsDisabled={setIsDisabled}
                  accept="image/*,.pdf"
                  isInputDisabled={isInputPhotoDisabled}
                />
              )}
              {photo.title && (
              <div className="addEvent-page__form-file-container-content-img">
                <img src={photo.url} alt={photo.title} className="addEvent-page__form-file-container-content-img-preview" />
                <p>
                  {photo.title.split('.')[0]}
                </p>
                <button type="button" className="delete" onClick={handleDeletePhoto}>
                  <FontAwesomeIcon icon={faTrash} size="2x" />
                </button>
              </div>
              )}
            </div>
            <div className="addEvent-page__form-file-container-content">
              {isInputVideoDisabled ? (
                <Tooltip title="Vous devez d'abord supprimer l'ancienne vidéo" placement="bottom">
                  <span>
                    <FileInput
                      name="video"
                      label="Ajouter une vidéo"
                      handleInputState={handleVideoState}
                      setEventIsDisabled={setIsDisabled}
                      accept="video/*"
                      isInputDisabled={isInputVideoDisabled}
                    />
                  </span>
                </Tooltip>
              ) : (
                <FileInput
                  name="video"
                  label="Ajouter une vidéo"
                  handleInputState={handleVideoState}
                  setEventIsDisabled={setIsDisabled}
                  accept="video/*"
                  isInputDisabled={isInputVideoDisabled}
                />
              )}
              {video.title && (
              <div className="addEvent-page__form-file-container-content-img">
                <p>
                  {video.title.split('.')[0]}
                </p>
                <button type="button" className="delete" onClick={handleDeleteVideo}>
                  <FontAwesomeIcon icon={faTrash} size="2x" />
                </button>
              </div>
              )}
            </div>
          </div>
          <div className="addEvent-page__form-button">
            {isDisabled ? (
              <Tooltip title="Veuillez d'abord ajouter l'image ou la vidéo">
                <span>
                  <Button classname="normal-button validate" type="submit" name="Créer l'évènement" disabled />
                </span>
              </Tooltip>
            ) : (
              <Button classname="normal-button validate" type="submit" name="Créer l'évènement" />
            )}
          </div>
        </form>
      </div>
    </>
  );
}

AddEvent.propTypes = {
};
AddEvent.defaultProps = {
};
export default React.memo(AddEvent);
