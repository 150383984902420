/* eslint-disable camelcase */
import {
  getAllMenuparts, getOneMenupart, createMenupart, updateMenupart, deleteMenupart,
} from '../../utils/requests/menupartRequest';
import * as actions from '../actions';

const menupartMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_MENUPARTS: {
      const response = await getAllMenuparts();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllMenuparts(response.data));
      }
      return;
    }

    case actions.GET_ONE_MENUPART: {
      const { menupart } = store.getState();
      const response = await getOneMenupart(menupart.id);
      if (response.status === 200) {
        store.dispatch(actions.actionGetOneMenupart(response.data));
        store.dispatch(actions.actionSetMenupartInfos(response.data));
        if (response.data.meals) {
          // TODO action meal
        }
        if (response.data.photos) {
          store.dispatch(actions.actionSetPhotoInfos(response.data.photos[0]));
        }
      }
      return;
    }

    case actions.CREATE_MENUPART: {
      const { menupart, card, photo } = store.getState();
      const { name, ordinal, description } = menupart;
      const { id } = card;
      const menupartDatas = {
        name,
        ordinal,
        description,
        card_id: id,
      };
      const response = await createMenupart(menupartDatas);
      if (response.status === 200) {
        if (photo.title !== '') {
          store.dispatch(actions.actionSetPhotoOwnerId('menupart_id', response.data.id));
          await store.dispatch(actions.actionCreatePhoto());
        }
        store.dispatch(actions.actionResetMenupart());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    case actions.UPDATE_MENUPART: {
      const { menupart, card, photo } = store.getState();
      const { name, ordinal, description } = menupart;
      const { id } = card;
      const menupartDatas = {
        name,
        description,
        ordinal,
        card_id: id,
      };
      const response = await updateMenupart(menupart.id, menupartDatas);
      if (response.status === 200) {
        if (photo.title !== '') {
          store.dispatch(actions.actionCreatePhoto());
        }
        store.dispatch(actions.actionResetMenupart());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    case actions.DELETE_MENUPART: {
      const { menupart, photo } = store.getState();
      if (photo.title) {
        store.dispatch(actions.actionRemovePhotoFromFirebase());
      }
      const response = await deleteMenupart(menupart.id);
      if (response.status === 204) {
        store.dispatch(actions.actionResetMenupart());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    default:
      next(action);
  }
};

export default menupartMiddleware;
