import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import Card from '../components/MenuPage/Card/Card';
import {
  actionUpdateCardInput,
  actionGetOneCard,
} from '../redux/actions';

function CardContainer() {
  const [isCardChange, setIsCardChange] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const price = useSelector((state) => state.card.price);
  const { allMenuparts } = useSelector((state) => state.menupart);

  useEffect(() => {
    if (location.pathname === '/admin/menus/card') {
      dispatch(actionUpdateCardInput('id', 1));
      dispatch(actionGetOneCard());
    }
    if (location.pathname === '/admin/menus/lunch') {
      dispatch(actionUpdateCardInput('id', 2));
      dispatch(actionGetOneCard());
    }
    if (location.pathname === '/admin/menus/drink') {
      dispatch(actionUpdateCardInput('id', 3));
      dispatch(actionGetOneCard());
    }
  }, [location]);

  useEffect(() => {
    dispatch(actionGetOneCard());
  }, [isCardChange]);

  switch (location.pathname) {
    case '/admin/menus/card':
      return (
        <>
          <Helmet>
            <title>Admin - Menus</title>
            <meta
              name="robots"
              content="noindex"
            />
            <link rel="canonical" href="/admin/menus/card" />
          </Helmet>
          <Card
            allMenuparts={allMenuparts}
          />
        </>
      );
    case '/admin/menus/lunch':
      return (
        <>
          <Helmet>
            <title>Admin - Menus</title>
            <meta
              name="robots"
              content="noindex"
            />
            <link rel="canonical" href="/admin/menus/lunch" />
          </Helmet>
          <Card
            allMenuparts={allMenuparts}
            price={price}
            setIsCardChange={setIsCardChange}
            isCardChange={isCardChange}
          />
        </>
      );
    case '/admin/menus/drink':
      return (
        <>
          <Helmet>
            <title>Admin - Menus</title>
            <meta
              name="robots"
              content="noindex"
            />
            <link rel="canonical" href="/admin/menus/drink" />
          </Helmet>
          <Card
            allMenuparts={allMenuparts}
            drink
          />
        </>
      );
    default:
      break;
  }
}

CardContainer.propTypes = {};
CardContainer.defaultProps = {};
export default React.memo(CardContainer);
