/* eslint-disable camelcase */
import {
  getAllEvents, getOneEvent, createEvent, updateEvent, deleteEvent,
} from '../../utils/requests/eventRequest';
import * as actions from '../actions';

const eventMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_EVENTS: {
      const response = await getAllEvents();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllEvents(response.data));
      }
      return;
    }

    case actions.GET_ONE_EVENT: {
      const { event } = store.getState();
      const response = await getOneEvent(event.id);
      if (response.status === 200) {
        store.dispatch(actions.actionSetOneEvent(response.data));
        if (response.data.photos[0]) {
          store.dispatch(actions.actionSetPhotoInfos(response.data.photos[0]));
        }
        if (response.data.videos[0]) {
          store.dispatch(actions.actionSetVideoInfos(response.data.videos[0]));
        }
        store.dispatch(actions.actionSetEventInfos(response.data));
      }
      return;
    }

    case actions.CREATE_EVENT: {
      const { event, photo, video } = store.getState();
      const {
        title,
        date,
        group,
        description,
      } = event;
      const eventDatas = {
        title,
        date,
        group,
        description,
      };
      const response = await createEvent(eventDatas);
      if (response.status === 200) {
        if (photo.title !== '') {
          store.dispatch(actions.actionSetPhotoOwnerId('event_id', response.data.id));
          await store.dispatch(actions.actionCreatePhoto());
        }
        if (video.title !== '') {
          store.dispatch(actions.actionSetVideoOwnerId('event_id', response.data.id));
          await store.dispatch(actions.actionCreateEventVideo());
        }
        store.dispatch(actions.actionResetEvent());
        store.dispatch(actions.actionToggleMessage('addEventSuccess', true));
      }
      return;
    }

    case actions.UPDATE_EVENT: {
      const { event, photo, video } = store.getState();
      const {
        title,
        date,
        group,
        description,
      } = event;
      const eventDatas = {
        title,
        date,
        group,
        description,
      };
      if (photo.title !== '') {
        store.dispatch(actions.actionCreatePhoto());
      }
      if (video.title !== '') {
        store.dispatch(actions.actionCreateEventVideo());
      }
      const response = await updateEvent(event.id, eventDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetEvent());
        store.dispatch(actions.actionToggleMessage('updateEventSuccess', true));
      }
      return;
    }

    case actions.DELETE_EVENT: {
      const { event, photo, video } = store.getState();
      if (photo.title) {
        store.dispatch(actions.actionRemovePhotoFromFirebase());
      }
      if (video.title) {
        store.dispatch(actions.actionRemoveVideoFromFirebase());
      }
      const response = await deleteEvent(event.id);
      if (response.status === 204) {
        store.dispatch(actions.actionResetEvent());
        store.dispatch(actions.actionGetAllEvents());
        store.dispatch(actions.actionToggleMessage('deleteEventSuccess', true));
      }
      return;
    }

    default:
      next(action);
  }
};

export default eventMiddleware;
