/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Modal, Box, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionDeleteMeal,
} from '../../redux/actions';
import Button from '../Buttons/Button/Button';

import './modal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'rgba(94, 94, 94, 0.865)',
  color: '#fff',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

function CreatMealModal({
  openModal,
  closeModal,
  modalTitle,
}) {
  const dispatch = useDispatch();

  const {
    title,
  } = useSelector((state) => state.meal);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionDeleteMeal());
    closeModal();
  };

  return (
    <Modal open={openModal} onClose={closeModal}>
      <Box sx={style}>
        <Typography id="modal__title" variant="h4" sx={{ textAlign: 'center' }}>
          {modalTitle}
          {' '}
          &quot;
          <span style={{ fontWeight: 'bold' }}>{title}</span>
          &quot;
          {' '}
          ?
        </Typography>
        <form className="modal__form" onSubmit={handleSubmit}>
          <div className="modal__form-button">
            <Button classname="normal-button cancel modal__form-button-button" type="button" name="Annuler" onClick={closeModal} />
            <Button classname="normal-button validate modal__form-button-button" type="submit" name="Supprimer" />
          </div>
        </form>
      </Box>
    </Modal>
  );
}

CreatMealModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
};
CreatMealModal.defaultProps = {
};
export default React.memo(CreatMealModal);
