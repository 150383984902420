export const SET_PHOTO_INFOS = 'SET_PHOTO_INFOS';
export const SET_PHOTO_OWNER_ID = 'SET_PHOTO_OWNER_ID';
export const CREATE_PHOTO = 'CREATE_PHOTO';
export const REMOVE_PHOTO_FROM_FIREBASE = 'REMOVE_PHOTO_FROM_FIREBASE';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const RESET_PHOTO = 'RESET_PHOTO';

export function actionSetPhotoInfos(data) {
  return { type: SET_PHOTO_INFOS, payload: data };
}

export function actionSetPhotoOwnerId(name, value) {
  return { type: SET_PHOTO_OWNER_ID, payload: { name, value } };
}

export function actionCreatePhoto() {
  return { type: CREATE_PHOTO };
}

export function actionRemovePhotoFromFirebase() {
  return { type: REMOVE_PHOTO_FROM_FIREBASE };
}

export function actionDeletePhoto() {
  return { type: DELETE_PHOTO };
}

export function actionResetPhoto() {
  return { type: RESET_PHOTO };
}
