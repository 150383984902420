import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { actionLogout } from '../../redux/actions';
import Button from '../Buttons/Button/Button';

import './adminmain.scss';

function Main({
  children,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showReturnButton, setReturnButton] = useState(false);
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.login.isLogged);

  useEffect(() => {
    if ((location.pathname !== '/admin/home')) {
      setReturnButton(true);
    } else {
      setReturnButton(false);
    }
  }, [location]);

  const handleClickDisconnect = () => {
    dispatch(actionLogout(false));
  };

  const handleReturnClick = () => {
    if (location.pathname === '/login') {
      return navigate('/', { replace: true });
    }
    return navigate('/admin/home', { replace: true });
  };

  return (
    <main className="admin__main">
      <div className="admin__main-header">
        {showReturnButton && (
          <Tooltip title={location.pathname === '/login' ? "Retourner  la page d'accueil" : 'Retourner au menu principal'} placement="right">
            <button type="button" className="admin__main-return-button" onClick={() => { handleReturnClick(); }}>
              <FontAwesomeIcon icon={faLeftLong} size="sm" />
              <span className="admin__main-return-button-span">Retour</span>
            </button>
          </Tooltip>
        )}
        <h1 className="admin__main-header-title">Page Admin</h1>
        {isLogged && (
        <Button type="button" classname="disconnect-button cancel disconnect" name="Se déconnecter" onClick={handleClickDisconnect} />
        )}
      </div>
      {children}
    </main>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
Main.defaultProps = {
};
export default React.memo(Main);
