/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

function IconButton({
  tooltipText,
  placement,
  classname,
  onClick,
  buttonSize,
  icon,
}) {
  const renderGoodIcon = (goodIcon) => {
    switch (goodIcon) {
      case 'faPlus':
        return (
          <FontAwesomeIcon icon={faPlus} size={buttonSize} inverse />
        );
      case 'faPen':
        return (
          <FontAwesomeIcon icon={faPen} size={buttonSize} inverse />
        );
      case 'faTrash':
        return (
          <FontAwesomeIcon icon={faTrash} size={buttonSize} inverse />
        );
      default:
        break;
    }
  };

  return (
    <Tooltip title={tooltipText} placement={placement}>
      <button type="button" className={classname} onClick={onClick}>
        {renderGoodIcon(icon)}
      </button>
    </Tooltip>
  );
}

IconButton.propTypes = {
  tooltipText: PropTypes.string,
  placement: PropTypes.string,
  classname: PropTypes.string,
  onClick: PropTypes.func,
  buttonSize: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
IconButton.defaultProps = {
  tooltipText: '',
  placement: '',
  classname: '',
  onClick: null,

};
export default React.memo(IconButton);
