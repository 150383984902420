/* eslint-disable camelcase */
import { ref, deleteObject } from 'firebase/storage';
import {
  createPhoto, deletePhoto,
} from '../../utils/requests/photoRequest';
import { storage } from '../../firebase';
import * as actions from '../actions';

const photoMiddleware = (store) => (next) => async (action) => {
  const { photo } = store.getState();
  const {
    id,
    title,
    url,
    album_id,
    event_id,
    menupart_id,
  } = photo;
  switch (action.type) {
    case actions.CREATE_PHOTO: {
      const photoDatas = {
        title,
        url,
        album_id,
        event_id,
        menupart_id,
      };
      const response = await createPhoto(photoDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetPhoto());
      }
      return;
    }

    case actions.REMOVE_PHOTO_FROM_FIREBASE: {
      if (title) {
        const imageRef = ref(storage, `images/${title}`);
        await deleteObject(imageRef);
      }
      if (id) {
        await deletePhoto(id);
        store.dispatch(actions.actionResetPhoto());
      } else {
        store.dispatch(actions.actionResetPhoto());
      }
      return;
    }

    case actions.DELETE_PHOTO: {
      if (id) {
        await deletePhoto(id);
      }
      store.dispatch(actions.actionResetPhoto());
      return;
    }
    default:
      next(action);
  }
};

export default photoMiddleware;
