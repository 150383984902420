export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const DELETE_USER_INFORMATION = 'DELETE_USER_INFORMATION';

export function actionSetUserInformation(data) {
  return {
    type: SET_USER_INFORMATION,
    payload: data,
  };
}

export function actionResetUserInformation() {
  return {
    type: DELETE_USER_INFORMATION,
  };
}
