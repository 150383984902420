/* eslint-disable camelcase */
import { ref, deleteObject } from 'firebase/storage';
import { storage } from '../../firebase';
import {
  createVideo, deleteVideo,
} from '../../utils/requests/videoRequest';
import * as actions from '../actions';

const videoMiddleware = (store) => (next) => async (action) => {
  const { video } = store.getState();
  const {
    id,
    title,
    url,
    event_id,
  } = video;
  switch (action.type) {
    case actions.CREATE_VIDEO_EVENT: {
      const videoDatas = {
        title,
        url,
        event_id,
      };
      const response = await createVideo(videoDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetVideo());
      }
      return;
    }
    case actions.CREATE_VIDEO: {
      const videoDatas = {
        title,
        url,
      };
      const response = await createVideo(videoDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetVideo());
      }
      return;
    }
    case actions.REMOVE_VIDEO_FROM_FIREBASE: {
      if (title) {
        const videoRef = ref(storage, `videos/${title}`);
        await deleteObject(videoRef);
      }
      if (id) {
        await deleteVideo(id);
        store.dispatch(actions.actionResetVideo());
      } else {
        store.dispatch(actions.actionResetVideo());
      }
      return;
    }
    case actions.DELETE_VIDEO: {
      if (id) {
        await deleteVideo(id);
      }
      store.dispatch(actions.actionResetVideo());
      return;
    }
    default:
      next(action);
  }
};

export default videoMiddleware;
