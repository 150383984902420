import apiAxios from './index';

/**
 * function used to make a http request to get all photos
 * @returns response with photos in an array of object
 */
export async function getAllPhotos() {
  try {
    const response = await apiAxios.get('/photos');
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to get one photo
 * @param {string} id id of the photo you want to get
 * @returns response with photo in an object
 */
export async function getOnePhoto(id) {
  try {
    const response = await apiAxios.get(`/photos/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to create a photo
 * @param {object} photoDatas photo informations (name)
 * @returns response with new photo in an object
 */
export async function createPhoto(photoDatas) {
  try {
    const response = await apiAxios.post('/photos', photoDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to update a photo
 * @param {string} id id of the photo you want to update
 * @param {object} photoDatas photo informations (name)
 * @returns response with updated photo in an object
 */
export async function updatePhoto(id, photoDatas) {
  try {
    const response = await apiAxios.patch(`/photos/${id}`, photoDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to delete a photo
 * @param {string} id id of the photo you want to delete
 * @returns response with an empty object
 */
export async function deletePhoto(id) {
  try {
    const response = await apiAxios.delete(`/photos/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}
