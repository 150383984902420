import apiAxios from './index';

/**
 * function used to make a http request to get all events
 * @returns response with events in an array of object
 */
export async function getAllEvents() {
  try {
    const response = await apiAxios.get('/events');
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to get one event
 * @param {string} id id of the event you want to get
 * @returns response with event in an object
 */
export async function getOneEvent(id) {
  try {
    const response = await apiAxios.get(`/events/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to create a event
 * @param {object} eventDatas event informations (name)
 * @returns response with new event in an object
 */
export async function createEvent(eventDatas) {
  try {
    const response = await apiAxios.post('/events', eventDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to update a event
 * @param {string} id id of the event you want to update
 * @param {object} eventDatas event informations (name)
 * @returns response with updated event in an object
 */
export async function updateEvent(id, eventDatas) {
  try {
    const response = await apiAxios.patch(`/events/${id}`, eventDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to delete a event
 * @param {string} id id of the event you want to delete
 * @returns response with an empty object
 */
export async function deleteEvent(id) {
  try {
    const response = await apiAxios.delete(`/events/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}
