/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../Buttons/IconButton/IconButton';
import CreateMenupartModal from '../../Modals/CreateMenupartModal';
import CreateMealModal from '../../Modals/CreateMealModal';
import EditMenupartModal from '../../Modals/EditMenupartModal';
import EditMealModal from '../../Modals/EditMealModal';
import EditCardModal from '../../Modals/EditCardModal';
import DeleteMealModal from '../../Modals/DeleteMealModal';
import { actionSetMenupartInfos, actionSetPhotoInfos, actionSetMealInfos } from '../../../redux/actions';
import './card.scss';

function Card({
  allMenuparts,
  price,
  setIsCardChange,
  isCardChange,
  lunch,
  drink,
}) {
  const dispatch = useDispatch();
  const [openCreateMenupartModal, setOpenCreateMenupartModal] = useState(false);
  const [openCreateMealModal, setOpenCreateMealModal] = useState(false);
  const [openEditCardModal, setOpenEditCardModal] = useState(false);
  const [openEditMenupartModal, setOpenEditMenupartModal] = useState(false);
  const [openEditMealModal, setOpenEditMealModal] = useState(false);
  const [openDeleteMealModal, setOpenDeleteMealModal] = useState(false);

  const renderCreateMenupartModal = () => (
    <CreateMenupartModal
      openModal={openCreateMenupartModal}
      closeModal={() => setOpenCreateMenupartModal(false)}
      modalTitle="Ajouter une catégorie"
      lunch={lunch}
    />
  );
  const renderCreateMealModal = () => {
    if (drink) {
      return (
        <CreateMealModal
          openModal={openCreateMealModal}
          closeModal={() => setOpenCreateMealModal(false)}
          modalTitle="Ajouter une boisson"
          drink={drink}
        />
      );
    }
    return (
      <CreateMealModal
        openModal={openCreateMealModal}
        closeModal={() => setOpenCreateMealModal(false)}
        modalTitle="Ajouter un plat"
        lunch={lunch}
      />
    );
  };
  const renderEditCardModal = () => (
    <EditCardModal
      openModal={openEditCardModal}
      closeModal={() => setOpenEditCardModal(false)}
      modalTitle="Modifier le prix"
      setIsCardChange={setIsCardChange}
      isCardChange={isCardChange}
      lunch={lunch}
    />
  );
  const renderEditMenupartModal = () => (
    <EditMenupartModal
      openModal={openEditMenupartModal}
      closeModal={() => setOpenEditMenupartModal(false)}
      modalTitle="Modifier la catégorie"
      lunch={lunch}
    />
  );
  const renderEditMealModal = () => {
    if (drink) {
      return (
        <EditMealModal
          openModal={openEditMealModal}
          closeModal={() => setOpenEditMealModal(false)}
          modalTitle="Modifier la boisson"
          drink={drink}
        />
      );
    }
    return (
      <EditMealModal
        openModal={openEditMealModal}
        closeModal={() => setOpenEditMealModal(false)}
        modalTitle="Modifier le plat"
        lunch={lunch}
      />
    );
  };
  const renderDeleteMealModal = () => {
    if (drink) {
      return (
        <DeleteMealModal
          openModal={openDeleteMealModal}
          closeModal={() => setOpenDeleteMealModal(false)}
          modalTitle="Supprimer la boisson"
        />
      );
    }
    return (
      <DeleteMealModal
        openModal={openDeleteMealModal}
        closeModal={() => setOpenDeleteMealModal(false)}
        modalTitle="Supprimer le plat"
      />
    );
  };

  const handleOpenModal = (modal, item = null) => {
    switch (modal) {
      case 'createMenupart':
        setOpenCreateMenupartModal(true);
        break;
      case 'createMeal':
        dispatch(actionSetMenupartInfos(item));
        setOpenCreateMealModal(true);
        break;
      case 'editCard':
        if (item && item.photos[0]) {
          dispatch(actionSetPhotoInfos(item.photos[0]));
        }
        setOpenEditCardModal(true);
        break;
      case 'editMenupart':
        dispatch(actionSetMenupartInfos(item));
        dispatch(actionSetPhotoInfos(item.photos[0]));
        setOpenEditMenupartModal(true);
        break;
      case 'editMeal':
        dispatch(actionSetMealInfos(item));
        setOpenEditMealModal(true);
        break;
      case 'deleteMeal':
        dispatch(actionSetMealInfos(item));
        setOpenDeleteMealModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-page">
      {renderCreateMenupartModal()}
      {renderCreateMealModal()}
      {renderEditCardModal()}
      {renderEditMenupartModal()}
      {renderEditMealModal()}
      {renderDeleteMealModal()}
      <div className="card-page__header">
        <button type="button" className="card-page__header-button" onClick={() => handleOpenModal('createMenupart')}>
          <FontAwesomeIcon icon={faPlus} size="2x" />
          <span className="card-page__header-button-span">Ajouter une catégorie</span>
        </button>
        {lunch && (
          <div className="card-page__header-price">
            <p className="card-page__header-price-content">
              {price}
              {' '}
              €
            </p>
            <IconButton
              tooltipText="Modifier le prix"
              placement="right"
              classname="card-page__header-price-button"
              buttonSize="2x"
              icon="faPen"
              onClick={() => handleOpenModal('editCard')}
            />
          </div>
        )}
      </div>
      {allMenuparts.sort((a, b) => a.ordinal - b.ordinal).map((menupart) => (
        <section key={menupart.id} className="card-page__section">
          <div className="card-page__section-header">
            <h3 className="card-page__section-header-title">{menupart.name}</h3>
            <div className="card-page__section-header-div">
              <IconButton
                tooltipText="Modifier la catégorie"
                placement="left"
                classname="card-page__section-header-button"
                buttonSize="2x"
                icon="faPen"
                onClick={() => handleOpenModal('editMenupart', menupart)}
              />
              <IconButton
                tooltipText={drink ? 'Ajouter une boisson' : 'Ajouter un plat'}
                placement="right"
                classname="card-page__section-header-button"
                buttonSize="2x"
                icon="faPlus"
                onClick={() => handleOpenModal('createMeal', menupart)}
              />
            </div>
          </div>
          {menupart.meals.sort((a, b) => a.id - b.id).map((meal) => (
            <article key={meal.id} className="card-page__article">
              <div className="card-page__article-section">
                <div className="card-page__article-section-info">
                  <div className="card-page__article-section-info-title">
                    <p className="card-page__article-section-info-title-text">{meal.title}</p>
                    {!lunch && !meal.halfprice && (
                      <p className="card-page__article-section-info-title-normal_price">
                        {meal.price}
                        {' '}
                        €
                      </p>
                    )}
                    {drink && meal.halfprice && (
                      <>
                        {meal.quarterprice && (
                        <div className="card-page__article-section-info-title-container">
                          <p>25cl</p>
                          <p className="card-page__article-section-info-title-price">
                            {meal.quarterprice}
                            {' '}
                            €
                          </p>
                        </div>
                        )}
                        {meal.halfprice && (
                        <div className="card-page__article-section-info-title-container">
                          <p>50cl</p>
                          <p className="card-page__article-section-info-title-price">
                            {meal.halfprice}
                            {' '}
                            €
                          </p>
                        </div>
                        )}
                        {meal.literprice && (
                        <div className="card-page__article-section-info-title-container">
                          <p>1L</p>
                          <p className="card-page__article-section-info-title-price">
                            {meal.literprice}
                            {' '}
                            €
                          </p>
                        </div>
                        )}
                      </>
                    )}
                  </div>
                  <p className="card-page__article-section-info-title-description">{meal.description}</p>
                </div>
                <div className="card-page__article-section-button">
                  <IconButton
                    tooltipText={drink ? 'Modifier la boisson' : 'Modifier le plat'}
                    placement="left"
                    classname="card-page__article-button"
                    buttonSize="2x"
                    icon="faPen"
                    onClick={() => handleOpenModal('editMeal', meal)}
                  />
                  <IconButton
                    tooltipText={drink ? 'Supprimer la boisson' : 'Supprimer le plat'}
                    placement="right"
                    classname="card-page__article-button"
                    buttonSize="2x"
                    icon="faTrash"
                    onClick={() => handleOpenModal('deleteMeal', meal)}
                  />
                </div>
              </div>
            </article>
          ))}
        </section>
      ))}
    </div>
  );
}

Card.propTypes = {
  allMenuparts: PropTypes.arrayOf().isRequired,
  price: PropTypes.string,
  setIsCardChange: PropTypes.func,
  isCardChange: PropTypes.bool,
  lunch: PropTypes.bool,
  drink: PropTypes.bool,
};
Card.defaultProps = {
  price: '',
  setIsCardChange: null,
  isCardChange: false,
  lunch: false,
  drink: false,
};
export default React.memo(Card);
