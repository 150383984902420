import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionAppMount } from '../redux/actions';
import App from '../components/App/App';

function AppContainer() {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(actionAppMount());
  }, []);

  return (
    <App />
  );
}

AppContainer.propTypes = {};
AppContainer.defaultProps = {};
export default React.memo(AppContainer);
